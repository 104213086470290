import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import Loading from 'ui-components/Loading';
import {
  ILoginResponse,
  IAccessToken,
  IRefreshToken,
  IAccessTokenInfo
} from 'src/services/api/urls/auth/types';
import api from 'src/services/api';
import { useToast } from 'src/hooks/useToast';
import { ActiveTabSitesEnum } from 'src/pages/Sites/types';
import { tabId } from 'src';
import { AuthContextType } from './types';
import { tokenEvents } from './AuthEventEmitter';

export const AuthContext = createContext({} as AuthContextType);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'login'
  });

  const [isLoading, setIsLoading] = useState(false);

  const [loadingMessage, setLoadingMessage] = useState('');

  const { addToast, dismissAllToasts } = useToast();

  const navigate = useNavigate();

  const location = useLocation();

  const [accessToken, setAccessToken] = useLocalStorage<
    IAccessToken | undefined
  >('@manager/access_token');

  const [refreshToken, setRefreshToken] = useLocalStorage<
    IRefreshToken | undefined
  >('@manager/refresh_token');

  const [accessTokenInfo, setAccessTokenInfo] = useLocalStorage<
    IAccessTokenInfo | undefined
  >('@manager/access_token_info');

  const [, setIsMenuMinimized] = useLocalStorage<boolean>(
    '@manager/is_menu_minimized'
  );

  const [siteTimezone, setSiteTimezone] = useLocalStorage<string | undefined>(
    '@manager/set_site_timezone'
  );

  const [, setActiveTabSite] = useLocalStorage('@manager/activeTabSite');

  const renderToastUnauthorized = useCallback(() => {
    addToast(
      'warning',
      t(
        'Seu tempo de sessão expirou, faça login novamente para ter acesso ao sistema'
      ),
      { unique: true, autoDismiss: false, closable: true }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderToastSystemError = useCallback(() => {
    addToast(
      'warning',
      t(
        'Atualize a versão de firmware de todos os seus equipamentos. A partir do dia 15/08/24 equipamentos não atualizados poderão apresentar problemas para se conectar à plataforma InMaster.'
      ),
      { unique: true, autoDismiss: false, closable: true }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabsBroadcast = useMemo(() => new BroadcastChannel('tabs_channel'), []);

  const resetSession = useCallback((withouBroadcast?: boolean) => {
    if (!withouBroadcast) {
      tabsBroadcast.postMessage({
        type: `logout@${tabId}`,
        senderTabId: tabId
      });
    }
    setAccessToken(undefined);
    setRefreshToken(undefined);
    setAccessTokenInfo(undefined);
    setIsMenuMinimized(false);
    setActiveTabSite(ActiveTabSitesEnum.MY_SITES);
    navigate('/login');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogout = useCallback(
    async (type: string) => {
      setLoadingMessage(t('Saindo...'));
      setIsLoading(true);
      try {
        // Após o conta unica deslogar, é necessário deslogar do backend
        await api.auth.logout().then(() => {
          if (type.includes('unauthorized')) {
            /* Se o logout acontece porque o usuário não tem mais permissão para acessar o sistema,
            é necessário renderizar um toast e enviar uma mensagem para as outras tabs, para que elas
            também sejam deslogadas
            */
            renderToastUnauthorized();
            tabsBroadcast.postMessage({
              type, // tipo de logout: logout | unauthorized | unauthorized_401
              senderTabId: tabId // id da tab que enviou a mensagem
            });
          }
        });
        resetSession();
      } catch (e) {
        setIsLoading(false);
        if (type.includes('unauthorized')) {
          renderToastUnauthorized();
          tabsBroadcast.postMessage({
            type,
            senderTabId: tabId
          });
        }
        resetSession(true);
      }
      setIsLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [renderToastUnauthorized, resetSession, tabsBroadcast]
  );

  const logout = useCallback(
    (type: string) => {
      if (!refreshToken?.id_token || location.pathname.includes('logout')) {
        // evita que o logout seja feito mais de uma vez
        return;
      }

      // Url de logout do conta unica
      const urlAuth = `${process.env.REACT_APP_CONTA_INTELBRAS_URL}/auth/logout?id_token_hint=${refreshToken?.id_token}&post_logout_redirect_uri=${process.env.REACT_APP_CONTA_INTELBRAS_REDIRECT_URI_LOGOUT}/${type}&state=exited`;

      // evita que o logout seja feito mais de uma vez por tabs diferentes
      if (type !== 'logout' && !type.includes('unauthorized_401')) {
        if (type.split('@')[1] !== tabId) return;
      }

      // Redireciona a tela para o conta unica, para que ele faça o logout e retorne para o redirect_uri_logout
      window.location.href = new URL(urlAuth).href;

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [location.pathname, refreshToken?.id_token]
  );

  // Função para limpar os tokens das tabs que estão em segundo plano ou minimizadas
  const doIfUnauthorized = () => {
    renderToastUnauthorized();
    resetSession(true);
  };

  // Escuta as mensagens enviadas pelas tabs que já fizeram o primeiro logout
  tabsBroadcast.onmessage = ({ data }) => {
    const isSameTabThatSentMessage = data.type.split('@')[1] === tabId;
    const isHiddenTab = document.hidden || !document.hasFocus();

    if (data.type === 'login' && isHiddenTab) {
      window.location.reload();
    }
    if (data.type.includes('unauthorized_401') && !isSameTabThatSentMessage) {
      doIfUnauthorized();
    } else if (
      data.type.includes('unauthorized') &&
      !isSameTabThatSentMessage
    ) {
      doIfUnauthorized();
    } else if (data.type.includes('logout') && !isSameTabThatSentMessage) {
      resetSession(true);
    }
  };

  const getNewRefreshToken = useCallback(async () => {
    try {
      if (refreshToken) {
        const response = await api.auth.refresh({
          refresh_token: refreshToken?.refresh_token
        });
        const { access_token, refresh_token, access_token_info } =
          response.data as ILoginResponse;
        setAccessToken(access_token);
        setRefreshToken(refresh_token);
        setAccessTokenInfo(access_token_info);
      }
    } catch (error) {
      window.console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshToken]);

  const handleLogin = useCallback(
    async (event: { data: string | string[]; origin: string }) => {
      if (
        !event.data ||
        typeof event.data !== 'string' ||
        !event.data.includes('code')
      ) {
        return;
      }

      if (
        event.origin === window.location.origin ||
        event.origin === process.env.REACT_APP_CONTA_INTELBRAS_ORIGIN
      ) {
        setLoadingMessage(t('Aguarde...'));
        setIsLoading(true);

        try {
          const response = await api.auth.login(JSON.parse(event.data));
          const { access_token, refresh_token, access_token_info } =
            response.data as ILoginResponse;

          setAccessToken(access_token);
          setRefreshToken(refresh_token);
          setAccessTokenInfo(access_token_info);
          dismissAllToasts();
          renderToastSystemError();
          navigate('/sites');
          tabsBroadcast.postMessage({
            type: 'login',
            senderTabId: tabId
          });
        } catch (error) {
          window.console.error(error);
        }
        window.addEventListener('message', handleLogin);
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const login = useCallback(() => {
    const urlAuth = `${process.env.REACT_APP_CONTA_INTELBRAS_URL}/auth/authorize?response_type=code&client_id=${process.env.REACT_APP_CONTA_INTELBRAS_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_CONTA_INTELBRAS_REDIRECT_URI}&scope=openid`;
    window.open(
      urlAuth,
      'Conta Intelbras',
      `popup=1,width=440,height=600,toolbar=yes,resizable=yes,top=${
        (window.screen.height - 600) / 4
      },left=${(window.screen.width - 440) / 2}`
    );

    window.addEventListener('message', handleLogin, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleTokensUpdated = (
      newAccessToken: IAccessToken,
      newRefreshToken: IRefreshToken,
      access_token_info: IAccessTokenInfo
    ) => {
      setAccessToken(newAccessToken);
      setRefreshToken(newRefreshToken);
      setAccessTokenInfo(access_token_info);
    };

    // Adicione um ouvinte para o evento 'tokensUpdated'
    tokenEvents.addListener('tokensUpdated', handleTokensUpdated);

    // Limpa o listener do event quando o componente for desmontado
    return () => {
      tokenEvents.removeListener('tokensUpdated', handleTokensUpdated);
    };
  }, [setAccessToken, setAccessTokenInfo, setRefreshToken]);

  const contextValue = useMemo(
    () => ({
      accessToken,
      refreshToken,
      accessTokenInfo,
      login,
      logout,
      handleLogout,
      handleLogin,
      setAccessToken,
      resetSession,
      getNewRefreshToken,
      setSiteTimezone,
      siteTimezone
    }),
    [
      accessToken,
      refreshToken,
      accessTokenInfo,
      login,
      logout,
      handleLogout,
      handleLogin,
      setAccessToken,
      resetSession,
      getNewRefreshToken,
      setSiteTimezone,
      siteTimezone
    ]
  );

  return (
    <AuthContext.Provider value={contextValue}>
      <Loading
        id="loading-login"
        message={loadingMessage}
        value={40}
        indeterminate
        fullscreen
        show={isLoading}
      />
      {children}
    </AuthContext.Provider>
  );
};
