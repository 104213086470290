import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'ui-components/Modal';
import classNames from 'classnames';
import { useQuery } from 'react-query';
import api from 'src/services/api';
import Skeleton from 'react-loading-skeleton';
import style from './UpdateFirmwareModal.module.css';
import LatestCurrentFirmwareVersion from './LatestCurrentFirmwareVersion';
import SearchFirmware from './SearchFirmware';
import { ISelectedFirmwareVersion } from '../types';
import UpdateToLatestFirmwareVersionModal from './UpdateToLatestFirmwareVersionModal';
import { IDeviceDataToUpdateFireware, IFirmware } from './types';

interface IProps {
  show: boolean;
  onClose: () => void;
  setSelectedFirmwareVersion: React.Dispatch<
    React.SetStateAction<ISelectedFirmwareVersion | null>
  >;
  device: IDeviceDataToUpdateFireware;
}

const UpdateFirmwareModal = ({
  onClose,
  show,
  setSelectedFirmwareVersion,
  device
}: IProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'devices.details'
  });

  const [isLatestVersion, setIsLatestVersion] = useState(true);
  const [showSearchFirmwareVersion, setShowSearchFirmwareVersion] =
    useState(false);
  const [latestFirmwareVersion, setLatestFirmwareVersion] = useState<IFirmware>(
    {} as IFirmware
  );

  const handleSelectFirmwareVersion = (firmwareVersion: string) => {
    setSelectedFirmwareVersion({
      device_id: device.id,
      firmware_version: firmwareVersion
    });
    setShowSearchFirmwareVersion(false);
    onClose();
  };

  const getLatestFirmwareVersion = () => {
    return api.devices.firmwares.getLastFirmware(device?.model);
  };

  const renderSkeletons = () => {
    return (
      <div className="d-flex justify-center align-center flex-column">
        <Skeleton
          height={135}
          width={135}
          borderRadius={100}
          className="mt-1"
        />
        <Skeleton height={20} width={400} className="mt-4 mb-7" />

        <Skeleton height={20} width={250} className="mb-10" />
      </div>
    );
  };

  const { isFetching } = useQuery(
    ['lastFirmwareVersionQuery', device?.model],
    getLatestFirmwareVersion,
    {
      onSuccess: ({ data }) => {
        if (data.firmwares[0].version !== device.firmware_version) {
          setLatestFirmwareVersion(data.firmwares[0]);
          setIsLatestVersion(false);
        } else {
          setIsLatestVersion(true);
        }
      },
      enabled: show && !!device.model
    }
  );

  return (
    <Modal
      width="750px"
      show={show}
      closable
      title={t('Atualizar firmware')}
      onClose={() => {
        onClose();
        setShowSearchFirmwareVersion(false);
      }}>
      <span className={classNames([style.subtitle])}>
        <span className="text-bold">
          {t('Versão de firmware atual do equipamento')}:{' '}
        </span>
        <span id={`current-firmware-version-${device?.firmware_version}`}>
          {device?.firmware_version}
        </span>
      </span>

      {isFetching ? (
        renderSkeletons()
      ) : (
        <>
          {isLatestVersion && !showSearchFirmwareVersion && (
            <LatestCurrentFirmwareVersion />
          )}

          {!isLatestVersion && !showSearchFirmwareVersion && (
            <UpdateToLatestFirmwareVersionModal
              handleSelectFirmwareVersion={handleSelectFirmwareVersion}
              latestFirmwareVersion={latestFirmwareVersion}
            />
          )}

          {showSearchFirmwareVersion && (
            <SearchFirmware
              handleSelectFirmwareVersion={handleSelectFirmwareVersion}
              device={device}
            />
          )}

          {!showSearchFirmwareVersion && (
            <div className="d-flex justify-center">
              <button
                className={classNames([style.textButton, 'mb-10'])}
                onClick={() => setShowSearchFirmwareVersion(true)}
                type="button">
                {t('Alterar firmware para uma versão anterior')}
              </button>
            </div>
          )}
        </>
      )}
    </Modal>
  );
};

export default UpdateFirmwareModal;
