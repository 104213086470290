export const initClarity = () =>
  ((c, l, a, r, i, t, y) => {
    const CLARITYCODE = process.env.REACT_APP_CLARITY_CODE;
    if ((CLARITYCODE !== undefined && CLARITYCODE.length === 0) || !CLARITYCODE) return;
    c[a] =
      c[a] ||
      function () {
        (c[a].q = c[a].q || []).push(arguments);
      };
    t = l.createElement(r);
    t.async = 1;
    t.src = `https://www.clarity.ms/tag/${i}`;
    y = l.getElementsByTagName(r)[0];
    y.parentNode.insertBefore(t, y);
  })(window, document, 'clarity', 'script', process.env.REACT_APP_CLARITY_CODE);
